import useTranslation from 'next-translate/useTranslation';
import { useAccount } from 'wagmi';
import { useClaimAirdrop } from '../../utils/hooks/airdrop/useClaimAirdrop';
import { useIsEligibleForAirdrop } from '../../utils/hooks/airdrop/useIsEligibleForAirdrop';
import BaseBox from '../base/BaseBox';
import BaseButton from '../base/BaseButton';
import * as viem from 'viem';

const AirDropToClaim = ({ className }: { className: string }) => {
  const { t } = useTranslation('airdrops');
  const { address } = useAccount();
  const {
    isEligible,
    amount,
    rewardAmount,
    refetch: refetchIsEligibleForAirdrop,
  } = useIsEligibleForAirdrop(address!);
  const { claimAidrop, claimAidropStatus } = useClaimAirdrop({
    to: address!,
    amount,
    rewardAmount,
    onTransactionSuccess() {
      refetchIsEligibleForAirdrop();
    },
  });

  if (!isEligible) {
    return <></>;
  }

  return (
    <BaseBox className={className} title={t('YOU_HAVE_ASSETS_TO_CLAIM')}>
      <div className="mb-6">
        {amount !== 0n && (
          <p className="my-2">
            <strong>MIC: </strong> {viem.formatEther(amount || 0n)}
          </p>
        )}

        {rewardAmount !== 0n && (
          <p className="my-2">
            <strong>USDC: </strong> {viem.formatUnits(rewardAmount || 0n, 6)}
          </p>
        )}
      </div>

      <BaseButton
        className="min-w-[200px]"
        onClick={claimAidrop}
        isLoading={claimAidropStatus === 'loading'}
        disabled={
          !claimAidrop ||
          (claimAidropStatus !== 'idle' && claimAidropStatus !== 'success')
        }
      >
        {t('CLAIM')}
      </BaseButton>
    </BaseBox>
  );
};

export default AirDropToClaim;
