import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import useTranslation from 'next-translate/useTranslation';
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
} from 'wagmi';
import { Asset, RewardAsset } from '../../../types/Asset';
import { TransactionEventHandlers } from '../../../types/TransactionEventHandlers';
import { CONFIRMATIONS_AMOUNT } from '../../constants';
import { useNotifiacationHandlers } from '../../useToast';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { multiErc20WeightedLockerABI } from '../generated';

export function useStakeToken({
  selectedTokenToStake,
  selectedRewardAsset,
  amount,
  allowance,
  lockPeriodId,
  onTransactionSubmitted,
  onTransactionSuccess,
  onSubmitError,
  onTransactionError,
  onTransactionSettled,
}: {
  selectedTokenToStake: Asset | undefined;
  selectedRewardAsset: RewardAsset | undefined;
  amount: bigint | undefined;
  allowance: bigint | undefined;
  lockPeriodId: string | undefined;
} & TransactionEventHandlers) {
  const { t } = useTranslation('common');
  const handlers = useNotifiacationHandlers({
    onTransactionSubmitted,
    onTransactionSuccess,
    onSubmitError,
    onTransactionError,
    onTransactionSettled,
  });
  const { address } = useAccount();
  const { locker } = useProtocolContractAddresses();
  const addRecentTransaction = useAddRecentTransaction();

  const { config: stakeConfig } = usePrepareContractWrite({
    address: locker,
    abi: multiErc20WeightedLockerABI,
    functionName: 'stake',
    args: [
      BigInt(selectedTokenToStake?.stakingAssetIndex ?? 0),
      BigInt(selectedRewardAsset?.stakingContractIndex ?? 0),
      amount!,
      BigInt(lockPeriodId ?? 0),
    ],
    enabled: !!amount && !!allowance && allowance >= amount && amount > 0 && !!address,
  });

  const { data: stakeTokenTxData, write: stakeToken } = useContractWrite({
    ...stakeConfig,
    onSuccess(data) {
      handlers.onTransactionSubmitted?.(data.hash, t('STAKING'));
    },
    onError(error) {
      handlers.onSubmitError?.(error);
    },
  });

  const { data: stakeTokenData, status: stakeTokenStatus } = useWaitForTransaction({
    hash: stakeTokenTxData?.hash,
    confirmations: CONFIRMATIONS_AMOUNT,
    onSuccess() {
      handlers.onTransactionSuccess?.(t('STAKE_SUCCESS'));
      addRecentTransaction({
        hash: stakeTokenTxData?.hash ?? '',
        description: t('STAKE_SUCCESS'),
      });
    },
    onError(error) {
      handlers.onTransactionError?.(error);
    },
    onSettled(data, error) {
      handlers.onTransactionSettled?.(data?.transactionHash ?? '', error);
    },
  });

  return {
    stakeTokenData,
    stakeTokenStatus,
    stakeToken: stakeToken,
  };
}
