export const MAX_EVENTS_PER_PAGE = 3;

export const getPaginatedArray = <T>(
  arr: T[],
  currentPage: number,
  maxEventsPerPage = MAX_EVENTS_PER_PAGE,
) => {
  const pagesCount = Math.ceil(arr.length / maxEventsPerPage);
  const startSlice = (currentPage - 1) * maxEventsPerPage;
  const endSlice = startSlice + maxEventsPerPage;

  return {
    pagesCount,
    startSlice,
    endSlice,
    paginatedArray: arr?.slice(startSlice, endSlice),
  };
};
