import useTranslation from 'next-translate/useTranslation';
import BaseBox from '../base/BaseBox';
import OngoingPeriod from '../rewards/OngoingPeriod';
import UpcomingPeriod from '../rewards/UpcomingPeriod';
import { SectionHeading } from '../rewards/SectionHeading';
import useOngoingReward from '../../utils/hooks/staking/useOngoingReward';
import useUpcomingReward from '../../utils/hooks/staking/useUpcomingReward';
import SkeletonLoader from '../base/SkeletonLoader';
import { useInterval } from 'react-use';
import { STAKING_CONTRACTS } from '../../utils/constants';
import { Address, useChainId } from 'wagmi';

const ONE_MINUTE_IN_MS = 60 * 1000;

const StakingStats = () => {
  const { t } = useTranslation('common');
  const chainId = useChainId();

  const oldStaking = STAKING_CONTRACTS['USDC.e'];
  const newStaking = STAKING_CONTRACTS['USDC'];

  const {
    reward: oldStakingReward,
    isLoading: isOldStakingLoading,
    refetch: refetchOldStakingReward,
  } = useOngoingReward({
    staking: oldStaking.address[chainId as keyof typeof oldStaking.address],
  });

  useInterval(() => {
    refetchOldStakingReward();
  }, ONE_MINUTE_IN_MS);

  if (isOldStakingLoading) {
    return (
      <BaseBox title={t('REWARDS_FOR_THE_COMMUNITY')}>
        <SectionHeading text={t('ONGOING_PERIOD')} />
        <SkeletonLoader className="h-12" />
      </BaseBox>
    );
  }

  if (oldStakingReward?.status === 'ongoing') {
    return (
      <StakingStatsInternal
        staking={oldStaking.address[chainId as keyof typeof oldStaking.address]}
      />
    );
  }

  return (
    <StakingStatsInternal
      staking={newStaking.address[chainId as keyof typeof newStaking.address]}
    />
  );
};

const StakingStatsInternal = ({ staking }: { staking: Address }) => {
  const { t } = useTranslation('common');

  const {
    reward: ongoing,
    isLoading: isLoadingOngoing,
    refetch: refetchOngoingReward,
  } = useOngoingReward({ staking });
  const {
    reward: upcoming,
    isLoading: isLoadingUpcomig,
    refetch: refetchUpcomingReward,
  } = useUpcomingReward({ staking });

  const isUpcomingRewardDataUpToDate =
    !!ongoing && !!upcoming && ongoing.period.endingDate < upcoming.period.endingDate;

  useInterval(() => {
    refetchOngoingReward();
    refetchUpcomingReward();
  }, ONE_MINUTE_IN_MS);

  if (ongoing?.status !== 'ongoing') {
    return null;
  }

  return (
    <BaseBox title={t('REWARDS_FOR_THE_COMMUNITY')}>
      <SectionHeading text={t('ONGOING_PERIOD')} />
      {isLoadingOngoing ? (
        <SkeletonLoader className="h-12" />
      ) : (
        <OngoingPeriod staking={staking} reward={ongoing} adminView={false} />
      )}

      {isUpcomingRewardDataUpToDate && (
        <>
          <SectionHeading text={t('UPCOMING_REWARD')} />
          {isLoadingUpcomig ? (
            <SkeletonLoader className="h-12" />
          ) : (
            <UpcomingPeriod reward={upcoming} />
          )}
        </>
      )}
    </BaseBox>
  );
};

export default StakingStats;
