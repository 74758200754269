import { useAccount, useContractRead, useSignTypedData } from 'wagmi';
import { Asset } from '../../../types/Asset';
import { useChainId } from '../useChainId';
import { secp256k1 } from '@noble/curves/secp256k1';
import { MAX_UINT_256 } from '../../constants';
import { Signature, hexToNumber } from 'viem';
import { erc20PermitABI } from '../generated';

export const getSplitSignature = (
  signedMessage: string | undefined,
): Signature | undefined => {
  try {
    if (!signedMessage) {
      throw new Error('No signed message');
    }
    const v = BigInt(hexToNumber(`0x${signedMessage.slice(130)}`));
    const { r, s } = secp256k1.Signature.fromCompact(signedMessage.slice(2, 130));

    return {
      v: BigInt(v),
      r: `0x${r.toString(16).padStart(64, '0')}`,
      s: `0x${s.toString(16).padStart(64, '0')}`,
    };
  } catch (error) {
    return undefined;
  }
};

export const usePermit = ({
  token,
  value,
  spenderAddress,
  onSuccess,
}: {
  token: Asset | undefined;
  value: bigint;
  spenderAddress: string;
  onSuccess?: (data: string, signature: Signature | undefined) => void;
}) => {
  const {
    data: signedMessage,
    status,
    signTypedData,
    reset,
  } = useSignTypedData({
    onSuccess: (data) => {
      onSuccess?.(data, getSplitSignature(data));
    },
  });

  const { address } = useAccount();
  const chainId = useChainId();

  const { data: walletNonce, refetch: refetchWalletNonce } = useContractRead({
    abi: erc20PermitABI,
    address: token?.address,
    functionName: 'nonces',
    args: [address as `0x${string}`],
    watch: true,
  });

  const { data: name } = useContractRead({
    abi: erc20PermitABI,
    address: token?.address,
    functionName: 'name',
  });

  return {
    data: {
      signedMessage,
      ...getSplitSignature(signedMessage),
    },
    status,
    reset,
    refetchWalletNonce,
    signTypedData: () =>
      signTypedData({
        domain: {
          name,
          version: '1',
          chainId,
          verifyingContract: token?.address,
        },
        types: {
          Permit: [
            {
              name: 'owner',
              type: 'address',
            },
            {
              name: 'spender',
              type: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
            },
            {
              name: 'nonce',
              type: 'uint256',
            },
            {
              name: 'deadline',
              type: 'uint256',
            },
          ],
        },
        primaryType: 'Permit',
        message: {
          owner: address!,
          spender: spenderAddress as `0x${string}`,
          value,
          nonce: walletNonce!,
          deadline: MAX_UINT_256,
        },
      }),
  };
};
