import useTranslation from 'next-translate/useTranslation';
import BaseBox from '../base/BaseBox';
import { useUserDeposits } from '../../utils/hooks/staking/useUserDeposits';
import CopyButton from '../common/CopyButton';
import BaseInput from '../base/BaseInput';
import { useRewardsAssets } from '../../utils/useAssets';

const MigrateStakingBanner = ({ className }: { className: string }) => {
  const { t } = useTranslation('migration');
  const { deposits } = useUserDeposits();

  const rewardTokens = useRewardsAssets();

  const usdce = rewardTokens.find((token) => token.symbol === 'USDC.e');
  const usdc = rewardTokens.find((token) => token.symbol === 'USDC');

  const isUserStakingToOldUSDC = deposits.some(
    (deposit) => deposit.reward.currency === 'USDC.e',
  );
  if (!isUserStakingToOldUSDC) {
    return <></>;
  }

  return (
    <BaseBox className={className} title={t('USDC_MIGRATION_TITLE')}>
      <div className="">
        <p className="pb-2">{t('USDC_MIGRATION_DESCRIPTION')}</p>
        <div className="flex flex-col gap-2 pb-4">
          <div className="flex max-w-5xl flex-col justify-start sm:flex-row sm:items-center sm:justify-end">
            <div className="w-full">
              {t('OLD_TOKEN')}: <strong>{usdce?.symbol}</strong>
            </div>
            <div className="relative inline-block w-[240px] sm:w-full sm:max-w-[400px]">
              <CopyButton fieldId={'old-usdc'} className="z-2 absolute right-3 top-3" />

              <BaseInput
                id={'old-usdc'}
                readonly={true}
                type="text"
                className="pr-16"
                value={usdce?.address}
              />
            </div>
          </div>
          <div className="flex max-w-5xl flex-col  justify-start sm:flex-row sm:items-center sm:justify-end">
            <div className="w-full">
              {t('NEW_TOKEN')}: <strong>{usdc?.symbol}</strong>
            </div>
            <div className="relative inline-block w-[240px] sm:w-full sm:max-w-[400px]">
              <CopyButton fieldId={'new-usdc'} className="z-2 absolute right-3 top-3" />

              <BaseInput
                id={'new-usdc'}
                readonly={true}
                type="text"
                className="pr-16"
                value={usdc?.address}
              />
            </div>
          </div>
        </div>
        <strong>{t('FOLLOWING_ACTIONS_NEEDED')}:</strong>
        <ul className="py-2 pl-4">
          <li>1. {t('REMOVE_ALL_DEPOSITS_FROM_OLD_STAKING_AND_CLAIM_REWARDS')}</li>
          <li>2. {t('STAKE_TO_NEW_TOKEN')}</li>
        </ul>
        <i>{t('THIS_MESSAGE_WILL_GO_AWAY_IF_MIGRATION_IS_COMPLETE')}</i>
      </div>
    </BaseBox>
  );
};

export default MigrateStakingBanner;
