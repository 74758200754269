import useTranslation from 'next-translate/useTranslation';
import {
  UseFormRegister,
  FieldValues,
  FieldErrorsImpl,
  UseFormSetValue,
} from 'react-hook-form';
import BaseInput from '../base/BaseInput';
import StakingAssetPicker from './StakingAssetPicker';

export interface StakingInputProps {
  register: UseFormRegister<FieldValues>;
  whenClickedMax: () => void;
  setValue: UseFormSetValue<FieldValues>;
  max?: number | string;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: unknown;
    }>
  >;
}

const StakingInput = ({
  register,
  errors,
  max,
  setValue,
  whenClickedMax,
}: StakingInputProps) => {
  const { t } = useTranslation('common');

  const sanitizeValue = (value: string) => {
    const digitsOnly = (text: string) => {
      return text.replaceAll(/[\D]/g, '');
    };

    if (value.includes('.')) {
      const [units, decimals] = value.split('.');

      return `${digitsOnly(units)}.${digitsOnly(decimals.slice(0, 18))}`;
    }

    return digitsOnly(value);
  };

  const formattedMaximumValue = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 6,
    useGrouping: false,
  }).format(new Number(max).valueOf());

  return (
    <div className="mt-3 mb-6">
      <div>
        <div className="flex">
          <label
            htmlFor="amount"
            className="mb-2 block text-sm font-medium text-gray-700 dark:text-white"
          >
            {t('TOKEN_TO_STAKE')}
          </label>

          <button
            type="button"
            onClick={whenClickedMax}
            className="ml-auto text-sm text-brand--4 hover:underline"
          >
            {`MAX${max ? ` (${formattedMaximumValue})` : ''}`}
          </button>
        </div>

        <div className="relative rounded-md shadow-sm">
          <BaseInput
            type="text"
            register={register('amount', {
              required: true,
              onChange: (event) => {
                const value = event?.nativeEvent?.target?.value || '';
                const sanitized = sanitizeValue(value);

                if (value !== sanitized) {
                  setValue('amount', sanitized);
                }
              },
            })}
          />

          <div className="absolute inset-y-0 right-0 flex items-center">
            <StakingAssetPicker register={register('currency')} />
          </div>
        </div>
      </div>

      {errors.amount && (
        <div className="mt-3 rounded-lg border border-red-600 bg-red-600/10 p-3 text-red-600">
          {errors.amount.type === 'required' && t('REQUIRED')}
        </div>
      )}
    </div>
  );
};

export default StakingInput;
