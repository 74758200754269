import { useState } from 'react';
import { getPaginatedArray } from '../getPaginatedArray';

export const usePagination = <T>(array: T[], maxEventsPerPage?: number) => {
  const [currentPage, setCurrentPage] = useState(1);

  if (!array) {
    return {
      currentPage,
      setCurrentPage,
      pagesCount: 0,
      paginatedArray: [],
    };
  }

  const { pagesCount, paginatedArray } = getPaginatedArray(
    array,
    currentPage,
    maxEventsPerPage,
  );

  return {
    currentPage,
    setCurrentPage,
    pagesCount,
    paginatedArray,
  };
};
