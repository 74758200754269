import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useQuery } from '@tanstack/react-query';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { TransactionEventHandlers } from '../../../types/TransactionEventHandlers';
import { CONFIRMATIONS_AMOUNT } from '../../constants';
import { useNotifiacationHandlers } from '../../useToast';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { merkleClaimableAirdropABI } from '../generated';
import { getWebisteURL } from '../../getWebsiteURL';

const getAirdropProof = async (to: string) => {
  const { data: hexProof, status } = await axios.get<`0x${string}`[]>(
    `${getWebisteURL()}/api/airdrop-proof?receiver=${to}`,
  );
  if (status !== 200) {
    console.error('Failed to get airdrop proof');
    return [];
  }
  return hexProof;
};

export const useClaimAirdrop = ({
  to,
  amount,
  rewardAmount,
  onTransactionSubmitted,
  onTransactionSuccess,
  onSubmitError,
  onTransactionError,
  onTransactionSettled,
}: {
  to: string;
  amount: bigint | undefined;
  rewardAmount: bigint | undefined;
} & TransactionEventHandlers) => {
  const { t } = useTranslation('common');
  const { airdrop } = useProtocolContractAddresses();
  const handlers = useNotifiacationHandlers({
    onTransactionSubmitted,
    onTransactionSuccess,
    onSubmitError,
    onTransactionError,
    onTransactionSettled,
  });
  const addRecentTransaction = useAddRecentTransaction();

  const { data: proof } = useQuery(['airdropProof', to, `${amount}`, `${rewardAmount}`], {
    queryFn: () => getAirdropProof(to),
    enabled: !!to && !!amount && !!rewardAmount,
  });

  const { config } = usePrepareContractWrite({
    abi: merkleClaimableAirdropABI,
    address: airdrop,
    functionName: 'claim',
    args: [to as `0x${string}`, amount!, rewardAmount!, proof!],
    enabled: !!proof,
  });

  const { data: claimAirdropTxData, write: claimAidrop } = useContractWrite({
    ...config,
    onSuccess(data) {
      handlers.onTransactionSubmitted?.(data.hash, t('CLAIMING_AIRDROP'));
    },
    onError(error) {
      handlers.onSubmitError?.(error);
    },
  });

  const { data: claimAidropData, status: claimAidropStatus } = useWaitForTransaction({
    hash: claimAirdropTxData?.hash,
    confirmations: CONFIRMATIONS_AMOUNT,
    onSuccess() {
      handlers.onTransactionSuccess?.(t('AIRDROP_CLAIMED'));
      addRecentTransaction({
        hash: claimAirdropTxData?.hash ?? '',
        description: t('AIRDROP_CLAIMED'),
      });
    },
    onError(error) {
      handlers.onTransactionError?.(error);
    },
    onSettled(data, error) {
      handlers.onTransactionSettled?.(data?.transactionHash ?? '', error);
    },
  });

  return {
    claimAidropData,
    claimAidropStatus,
    claimAidrop: claimAidrop as () => Promise<void>,
  };
};
