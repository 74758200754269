
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';
import StakingWidget from '../components/staking/StakingWidget';
import StakingStats from '../components/staking/StakingStats';
import WelcomeScreen from '../components/welcome/WelcomeScreen';
import AirDropToClaim from '../components/claim/AirDropToClaim';
import { useAccount } from 'wagmi';
import { useIsAppInitialized } from '../utils/hooks/useIsAppinitialized';
import UserRewardsWidget from '../components/rewards/UserRewardsWidget';
import MigrateStakingBanner from '../components/banner/MigrateStakingBanner';

export default function Home() {
  const { t } = useTranslation('common');
  const { address } = useAccount();

  if (!useIsAppInitialized()) {
    return null;
  }

  if (!address?.length) {
    return <WelcomeScreen />;
  }

  return (
    <div>
      <Head>
        <title>{t('MILKY_ICE_STAKING')}</title>
      </Head>

      <div className="grid space-y-8 lg:grid-cols-3 lg:gap-10">
        <AirDropToClaim className="box--money-bg col-span-3" />
        <MigrateStakingBanner className="col-span-3" />

        <div className="col-span-3 gap-8 space-y-8 lg:flex lg:space-y-0">
          <StakingWidget />

          <StakingStats />
        </div>

        <UserRewardsWidget className="col-span-3" />
      </div>
    </div>
  );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  