import { Deposit } from './types';
import DepositsTableRow from './DepositsTableRow';
import TableHead, { TableColumn } from '../base/TableHead';
import useTranslation from 'next-translate/useTranslation';

interface DepositsTableProps {
  items: Deposit[];
  refetchUserDeposits: () => void;
}

const DepositsTable = ({ items, refetchUserDeposits }: DepositsTableProps) => {
  const { t } = useTranslation('common');

  const columns: TableColumn[] = [
    {
      label: t('ASSET'),
      className: 'w-full mr-12 lg:w-1/4 lg:mr-0 my-auto text-left',
      headerClassName: 'px-3',
      slug: 'asset',
    },
    {
      label: t('DEPOSIT'),
      className: 'w-full mr-12 lg:w-1/4 lg:mr-0 my-auto text-left',
      headerClassName: 'px-1',
      slug: 'deposit',
    },
    {
      label: t('DATE'),
      className: 'w-full mr-12 lg:w-1/4 lg:mr-0 my-auto text-left',
      slug: 'date',
    },
    {
      label: '',
      className: 'lg:flex justify-center w-full lg:w-1/4',
      headerClassName: '',
      slug: 'unstake',
    },
  ];

  return (
    <div>
      <TableHead columns={columns} />

      <div>
        {items.map((item) => {
          return (
            <DepositsTableRow
              key={item.id}
              columns={columns}
              item={item}
              refetchUserDeposits={refetchUserDeposits}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DepositsTable;
