import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useRewardsAssets } from '../../utils/useAssets';
import { StakingInputProps } from './StakingInput';

type StakingOutputProps = Omit<
  StakingInputProps,
  'whenClickedMax' | 'register' | 'setValue'
> & {
  setValue: (value: string) => ReturnType<UseFormSetValue<FieldValues>>;
  selectedRewardTokenSymbol: string;
};

const StakingOutput = ({
  errors,
  setValue,
  selectedRewardTokenSymbol,
}: StakingOutputProps) => {
  const { t } = useTranslation('common');
  const baseClasses =
    'w-16 h-16 flex cursor-pointer border border-2 dark:bg-brand--5 items-center justify-center rounded-lg p-10 bg-brand--2';

  const activeClass = (reward: string) => {
    return reward === selectedRewardTokenSymbol
      ? 'border-brand--3 shadow-2xl shadow-brand--3/20'
      : 'border-white/0';
  };

  // MILKY SPECIFIC CODE, REMOVE THIS WHEN MIGRATING TO CDFD PLATFORM
  const rewardsAssets = useRewardsAssets().filter((asset) => asset.symbol !== 'USDC.e');

  return (
    <div className="relative block">
      <div>
        <label
          htmlFor="reward"
          className="block text-sm font-medium text-gray-700 dark:text-white"
        >
          {t('REWARD')}
        </label>

        <div className="mb-6 mt-3 grid max-w-[240px] grid-cols-3 gap-10">
          {rewardsAssets.map((asset) => (
            <button
              key={asset.symbol}
              className={clsx(baseClasses, activeClass(asset.symbol))}
              onClick={() => setValue(asset.symbol)}
            >
              <span>{asset.symbol}</span>
            </button>
          ))}
        </div>
      </div>

      {errors.reward && (
        <div className="mt-3 rounded-lg border border-red-600 bg-red-600/10 p-3 text-red-600">
          {errors.reward.type === 'required' && t('REQUIRED')}
        </div>
      )}
    </div>
  );
};

export default StakingOutput;
