import clsx from 'clsx';
import { Deposit } from './types';
import { formatDate } from '../../utils/formatDate';
import { useUnstakeToken } from '../../utils/hooks/staking/useUnstake';
import useTranslation from 'next-translate/useTranslation';
import BaseButton from '../base/BaseButton';
import { TableColumn } from '../base/TableHead';

interface DepositsTableRowProps {
  item: Deposit;
  columns: TableColumn[];
  isSelected?: boolean;
  refetchUserDeposits: () => void;
}

const DepositsTableRow = ({
  item,
  columns,
  refetchUserDeposits,
}: DepositsTableRowProps) => {
  const { t } = useTranslation('common');

  const { unstakeToken, unstakeTokenStatus } = useUnstakeToken({
    depositId: BigInt(item.id || 0),
    withRewards: false,
    onTransactionSuccess() {
      refetchUserDeposits();
    },
  });

  const getValue = (columnSlug: string) => {
    switch (columnSlug) {
      case 'asset':
        return item.asset;
      case 'deposit':
        return `${item.deposit.value} ${item.deposit.currency}`;
      case 'date':
        return formatDate(item.lockDate);
      default:
        return '';
    }
  };

  return (
    <div
      key={item.id}
      className="border-t py-3 px-2 first-of-type:border-t-0 dark:border-brand--5"
    >
      <div className="flex w-full flex-wrap">
        {columns.map((column) => {
          if (column.slug === 'unstake') {
            return (
              <div key={`${item.id}-${column.slug}`} className={column.className}>
                <BaseButton
                  size="extra-small"
                  variant="secondary"
                  onClick={unstakeToken}
                  disabled={
                    !unstakeToken ||
                    unstakeTokenStatus === 'loading' ||
                    (unstakeTokenStatus !== 'idle' && unstakeTokenStatus !== 'success')
                  }
                  className="my-4 max-w-[200px] whitespace-nowrap px-12 font-bold uppercase"
                >
                  {t('UNSTAKE')}
                </BaseButton>
              </div>
            );
          }

          return (
            <div
              key={`${item.id}-${column.slug}`}
              className={clsx(column.className, 'flex')}
            >
              <div className="my-3 mr-2 opacity-70 xl:my-0 xl:hidden">
                {column.label}:
              </div>

              <div className="my-3 font-medium xl:my-0">{getValue(column.slug)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DepositsTableRow;
