import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import useTranslation from 'next-translate/useTranslation';
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
} from 'wagmi';
import { RewardAsset } from '../../../types/Asset';
import { TransactionEventHandlers } from '../../../types/TransactionEventHandlers';
import { CONFIRMATIONS_AMOUNT } from '../../constants';
import { useNotifiacationHandlers, useToast } from '../../useToast';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { multiErc20WeightedLockerABI } from '../generated';

export function useCollectRewards({
  rewardAsset,
  onTransactionSubmitted,
  onTransactionSuccess,
  onSubmitError,
  onTransactionError,
  onTransactionSettled,
}: {
  rewardAsset: RewardAsset | undefined;
} & TransactionEventHandlers) {
  const { t } = useTranslation('common');
  const handlers = useNotifiacationHandlers({
    onTransactionSubmitted,
    onTransactionSuccess,
    onSubmitError,
    onTransactionError,
    onTransactionSettled,
  });
  const { address } = useAccount();

  const { locker } = useProtocolContractAddresses();
  const addRecentTransaction = useAddRecentTransaction();
  const { displayToast } = useToast();

  const { config: collectRewardsConfig } = usePrepareContractWrite({
    address: locker,
    abi: multiErc20WeightedLockerABI,
    functionName: 'collectRewards',
    args: [BigInt(rewardAsset?.stakingContractIndex || 0)],
    enabled: !!address && !!rewardAsset,
  });

  const { data: collectRewardsTxData, write: collectRewards } = useContractWrite({
    ...collectRewardsConfig,
    onSuccess(data) {
      handlers.onTransactionSubmitted?.(data.hash, t('COLLECTING_REWARDS'));
    },
    onError(error) {
      if (error.message.includes('ERC20: transfer amount exceeds balance')) {
        displayToast('error', t('INSUFFICIENT_STAKING_CONTRACT_BALANCE'));
        return;
      }
      handlers.onSubmitError?.(error);
    },
  });

  const { data: collectRewardsData, status: collectRewardsStatus } =
    useWaitForTransaction({
      hash: collectRewardsTxData?.hash,
      confirmations: CONFIRMATIONS_AMOUNT,
      onSuccess() {
        handlers.onTransactionSuccess?.(t('REWARDS_COLLECTED'));
        addRecentTransaction({
          hash: collectRewardsTxData?.hash ?? '',
          description: t('REWARDS_COLLECTED'),
        });
      },
      onError(error) {
        handlers.onTransactionError?.(error);
      },
      onSettled(data, error) {
        handlers.onTransactionSettled?.(data?.transactionHash ?? '', error);
      },
    });

  return {
    collectRewardsData,
    collectRewardsStatus,
    collectRewards: collectRewards as () => Promise<void>,
  };
}
