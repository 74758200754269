import * as viem from 'viem';
import { useAccount, useContractRead } from 'wagmi';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { multiErc20WeightedLockerABI } from '../generated';

export function useUserDepositsCount() {
  const account = useAccount();
  const { locker } = useProtocolContractAddresses();
  const {
    data: count,
    isLoading,
    refetch,
  } = useContractRead({
    address: locker,
    abi: multiErc20WeightedLockerABI,
    functionName: 'userDepositsCount',
    args: [account.address!],
    enabled: viem.isAddress(account.address!),
    watch: true,
  });

  return {
    count,
    isLoading,
    refetch,
  };
}
