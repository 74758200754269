import clsx from 'clsx';
import React from 'react';

const BasePagination = ({
  pagesCount,
  currentPage,
  setCurrentPage,
}: {
  pagesCount: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  if (pagesCount < 2) {
    return null;
  }

  return (
    <div className="my-4 flex items-center justify-center">
      {Array.from(Array(pagesCount), (_, i) => i + 1).map((index) => (
        <div key={index}>
          <div
            className={clsx(
              `m-2 inline-block min-w-[40px] cursor-pointer rounded-lg border border-brand--3 p-2 text-center
               text-sm transition hover:bg-brand--4 hover:text-white dark:border-brand--4`,
              {
                'pointer-events-none cursor-not-allowed border-none bg-brand--2 font-bold text-brand--4 dark:bg-brand--5':
                  currentPage === index,
              },
            )}
            onClick={() => setCurrentPage(index)}
          >
            {index}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BasePagination;
