import { useAccount, useContractRead } from 'wagmi';
import * as viem from 'viem';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { multiErc20WeightedLockerABI } from '../generated';

export function useEarnedRewards({
  stakingContractIndex,
}: {
  stakingContractIndex: number | undefined;
}) {
  const account = useAccount();
  const { locker } = useProtocolContractAddresses();
  const {
    data: amountOfRewards,
    isLoading,
    refetch,
  } = useContractRead({
    address: locker,
    abi: multiErc20WeightedLockerABI,
    functionName: 'earnedReward',
    args: [account.address!, BigInt(stakingContractIndex || 0)],
    enabled:
      stakingContractIndex !== undefined &&
      stakingContractIndex !== null &&
      viem.isAddress(account.address!),
    watch: true,
  });

  return {
    amountOfRewards,
    isLoading,
    refetch,
  };
}
