/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * This file contains functions to map return values from a contract call to a struct.
 * This is temporary as migration to viem caused the return values to be an array of values
 * instead of a struct.
 */

export const createManyReturnValuesToStructMapper =
  <T>(descriptor: any) =>
  (values: readonly any[]) =>
    values.map(createReturnValueToStructMapper<T>(descriptor)) as T[];

export const createReturnValueToStructMapper =
  <T>(descriptor: any) =>
  (values: readonly any[]) => {
    let i = 0;

    const mapValues = (values: readonly any[], outputs: any[]) => {
      if (!Array.isArray(values)) {
        return values;
      }
      return outputs.reduce((obj, outputDescription) => {
        if (outputDescription.type === 'tuple') {
          obj[outputDescription.name] = mapValues(
            values[i++],
            outputDescription.components!,
          );
        } else {
          obj[outputDescription.name] = values[i++];
        }

        return obj;
      }, {} as any);
    };

    return mapValues(values, descriptor.outputs!) as T;
  };
