import { useQuery } from '@tanstack/react-query';
import { useAccount, usePublicClient } from 'wagmi';

export const useIsSmartContractWalletConnected = () => {
  const client = usePublicClient();
  const { address } = useAccount();

  const { data: isSmartContractWallet } = useQuery({
    initialData: false,
    queryKey: [client, address],
    queryFn: async () => {
      if (!address) {
        return false;
      }

      const addressBytecode = await client.getBytecode({ address });
      return addressBytecode !== undefined && addressBytecode !== '0x';
    },
  });

  return isSmartContractWallet;
};
