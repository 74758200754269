import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { Asset, RewardAsset } from '../../../types/Asset';
import { TransactionEventHandlers } from '../../../types/TransactionEventHandlers';
import { CONFIRMATIONS_AMOUNT, MAX_UINT_256 } from '../../constants';
import { useNotifiacationHandlers } from '../../useToast';
import { usePermit } from '../token/usePermit';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { multiErc20WeightedLockerABI } from '../generated';
import { writeContract, prepareWriteContract, waitForTransaction } from '@wagmi/core';

export function useStakeTokenWithPermit({
  selectedTokenToStake,
  selectedRewardAsset,
  amount,
  lockPeriodId,
  onTransactionSubmitted,
  onTransactionSuccess,
  onSubmitError,
  onTransactionError,
  onTransactionSettled,
}: {
  selectedTokenToStake: Asset | undefined;
  selectedRewardAsset: RewardAsset | undefined;
  amount: bigint | undefined;
  allowance: bigint | undefined;
  lockPeriodId: string | undefined;
} & TransactionEventHandlers) {
  const { t } = useTranslation('common');
  const handlers = useNotifiacationHandlers({
    onTransactionSubmitted,
    onTransactionSuccess,
    onSubmitError,
    onTransactionError,
    onTransactionSettled,
  });
  const { locker } = useProtocolContractAddresses();
  const addRecentTransaction = useAddRecentTransaction();
  const [stakeTokenWithPermitStatus, setStakeTokenWithPermitStatus] = useState<
    'idle' | 'error' | 'loading' | 'success'
  >('idle');

  const {
    signTypedData,
    reset: resetSignedData,
    refetchWalletNonce,
  } = usePermit({
    spenderAddress: locker,
    token: selectedTokenToStake,
    value: amount!,
    async onSuccess(_signedMessage, signature) {
      try {
        setStakeTokenWithPermitStatus('loading');
        const { request } = await prepareWriteContract({
          address: locker,
          abi: multiErc20WeightedLockerABI,
          functionName: 'stakeWithPermit',
          args: [
            BigInt(selectedTokenToStake?.stakingAssetIndex ?? 0),
            BigInt(selectedRewardAsset?.stakingContractIndex ?? 0),
            amount!,
            BigInt(lockPeriodId ?? 0),
            MAX_UINT_256,
            Number(signature!.v),
            signature!.r,
            signature!.s,
          ],
        });

        const { hash } = await writeContract(request);
        handlers.onTransactionSubmitted?.(hash, t('STAKING'));
        await waitForTransaction({
          hash,
          confirmations: CONFIRMATIONS_AMOUNT,
        });
        handlers.onTransactionSuccess?.(t('STAKE_SUCCESS'));
        addRecentTransaction({
          hash,
          description: t('STAKE_SUCCESS'),
        });
        setStakeTokenWithPermitStatus('success');
      } catch (error) {
        handlers.onSubmitError?.(error as Error);
        setStakeTokenWithPermitStatus('error');
      } finally {
        resetSignedData();
        refetchWalletNonce();
      }
    },
  });

  return {
    stakeTokenWithPermitStatus,
    permitTokensAndStake: signTypedData,
  };
}
