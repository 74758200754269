import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import useTranslation from 'next-translate/useTranslation';
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
} from 'wagmi';
import { TransactionEventHandlers } from '../../../types/TransactionEventHandlers';
import { CONFIRMATIONS_AMOUNT } from '../../constants';
import { useNotifiacationHandlers } from '../../useToast';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { multiErc20WeightedLockerABI } from '../generated';

export function useUnstakeToken({
  depositId,
  withRewards = false,
  onTransactionSubmitted,
  onTransactionSuccess,
  onSubmitError,
  onTransactionError,
  onTransactionSettled,
}: {
  depositId: bigint | undefined;
  withRewards?: boolean;
} & TransactionEventHandlers) {
  const { t } = useTranslation('common');
  const handlers = useNotifiacationHandlers({
    onTransactionSubmitted,
    onTransactionSuccess,
    onSubmitError,
    onTransactionError,
    onTransactionSettled,
  });
  const { address } = useAccount();
  const { locker } = useProtocolContractAddresses();
  const addRecentTransaction = useAddRecentTransaction();

  const { config: unstakeConfig } = usePrepareContractWrite({
    address: locker,
    abi: multiErc20WeightedLockerABI,
    functionName: withRewards ? 'withdrawStakeAndReward' : 'withdraw',
    args: [depositId || 0n],
    enabled: !!depositId && !!address,
  });

  const { data: unstakeTokenTxData, write: unstakeToken } = useContractWrite({
    ...unstakeConfig,
    onSuccess(data) {
      handlers.onTransactionSubmitted?.(data.hash, t('UNSTAKING'));
    },
    onError(error) {
      handlers.onSubmitError?.(error);
    },
  });

  const { data: unstakeTokenData, status: unstakeTokenStatus } = useWaitForTransaction({
    hash: unstakeTokenTxData?.hash,
    confirmations: CONFIRMATIONS_AMOUNT,
    onSuccess() {
      handlers.onTransactionSuccess?.(t('UNSTAKE_SUCCESS'));
      addRecentTransaction({
        hash: unstakeTokenTxData?.hash ?? '',
        description: t('UNSTAKE_SUCCESS'),
      });
    },
    onError(error) {
      handlers.onTransactionError?.(error);
    },
    onSettled(data, error) {
      handlers.onTransactionSettled?.(data?.transactionHash ?? '', error);
    },
  });

  return {
    unstakeTokenData,
    unstakeTokenStatus,
    unstakeToken: unstakeToken,
  };
}
