import { useMemo } from 'react';
import { useAccount, useContractReads } from 'wagmi';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { useUserDepositsCount } from './useUserDepositsCount';
import { multiErc20WeightedLockerABI } from '../generated';

export const useAllUserDepositIds = () => {
  const account = useAccount();
  const { locker } = useProtocolContractAddresses();

  const { count } = useUserDepositsCount();

  const depositsToRead = useMemo(
    () =>
      Array.from({ length: Number(count) || 0 }, () => 1).map(
        (_, index) =>
          ({
            abi: multiErc20WeightedLockerABI,
            address: locker,
            functionName: 'userDepositIds',
            args: [account.address!, index],
          } as const),
      ),
    [count, account.address, locker],
  );

  const {
    data: depositIds,
    isLoading,
    refetch,
  } = useContractReads({
    contracts: depositsToRead,
    allowFailure: false,
    enabled: !!count,
    watch: true,
  });

  return {
    depositIds: (depositIds ?? []) as unknown as (bigint | undefined)[],
    isLoading,
    refetch,
  };
};
