import { useAccount, useContractReads } from 'wagmi';
import * as viem from 'viem';
import { Deposit, DepositStruct } from '../../../components/deposits/types';
import { STAKING_ASSETS, STAKING_REWARDS } from '../../constants';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { useAllUserDepositIds } from './useAllUserDepositIds';
import { multiErc20WeightedLockerABI } from '../generated';
import { createManyReturnValuesToStructMapper } from '../../../utils/returnValueMapper';

export const useUserDeposits = () => {
  const account = useAccount();
  const { locker } = useProtocolContractAddresses();

  const { depositIds } = useAllUserDepositIds();

  const {
    data: deposits = [],
    isLoading,
    refetch,
  } = useContractReads({
    contracts: depositIds?.map((id) => ({
      abi: multiErc20WeightedLockerABI,
      address: locker,
      functionName: 'userDeposits',
      args: [account.address!, BigInt(id || 0)],
    })),
    allowFailure: false,
    enabled: !!depositIds && depositIds.length > 0,
    watch: true,
    select: createManyReturnValuesToStructMapper<DepositStruct>(
      multiErc20WeightedLockerABI.find(
        (abi) => abi.type === 'function' && abi.name === 'userDeposits',
      ),
    ),
  });

  return {
    deposits: deposits
      ?.filter(Boolean)
      .map((deposit, index) => ({
        ...deposit,
        id: depositIds[index]!,
      }))
      .filter((deposit) => deposit.isOngoing)
      .map(toDeposit),
    isLoading,
    refetch,
  };
};

function toDeposit(depositStruct: DepositStruct): Deposit {
  const asset = STAKING_ASSETS.find(
    (stakingAsset) =>
      stakingAsset.stakingAssetIndex === Number(depositStruct.lockableAssetIndex),
  );
  const assetSymbol = asset?.symbol ?? ('N/A' as typeof STAKING_ASSETS[number]['symbol']);
  const rewardAsset = STAKING_REWARDS.find(
    (stakingAsset) =>
      stakingAsset.stakingContractIndex === Number(depositStruct.stakingContractIndex),
  );
  const rewardAssetSymbol =
    rewardAsset?.symbol ?? ('N/A' as typeof STAKING_REWARDS[number]['symbol']);

  const lockPeriodDuration = Number(depositStruct.lockPeriod.durationInSeconds);
  const period =
    lockPeriodDuration === 0
      ? 'NO_LOCK'
      : (`${lockPeriodDuration / 86400}_DAYS` as Deposit['period']);

  const progress =
    period === 'NO_LOCK'
      ? 0
      : Math.floor(
          ((Math.floor(Date.now() / 1000) - Number(depositStruct.depositTimestamp)) /
            lockPeriodDuration) *
            100,
        );

  return {
    id: depositStruct.id.toString(),
    asset: `${assetSymbol} -> ${rewardAssetSymbol}`,
    deposit: {
      currency: assetSymbol,
      value: viem.formatUnits(depositStruct.amountLocked, asset?.decimals ?? 18),
    },
    lockDate: new Date(Number(depositStruct.depositTimestamp) * 1000),
    period,
    progress: BigInt(progress),
    reward: {
      currency: rewardAssetSymbol,
      value: '', // this is not available in the locker contract, will be fetched later
    },
    unlockDate: new Date(Number(depositStruct.unlockAvailibleTimestamp) * 1000),
  };
}
