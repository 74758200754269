import useTranslation from 'next-translate/useTranslation';
import { useUserDeposits } from '../../utils/hooks/staking/useUserDeposits';
import { usePagination } from '../../utils/hooks/usePagination';
import { useRewardsAssets } from '../../utils/useAssets';
import BaseBox from '../base/BaseBox';
import BasePagination from '../base/BasePagination';
import SkeletonLoader from '../base/SkeletonLoader';
import DepositsTable from '../deposits/DepositsTable';
import { SectionHeading } from './SectionHeading';
import UserReward from './UserReward';

const UserRewardsWidget = ({ className }: { className: string }) => {
  const { t } = useTranslation('common');

  const { deposits, isLoading, refetch } = useUserDeposits();
  const {
    currentPage,
    pagesCount,
    paginatedArray: displayedDeposits,
    setCurrentPage,
  } = usePagination(deposits, 5);
  const rewards = useRewardsAssets();

  if (isLoading) {
    return (
      <BaseBox title={t('YOUR_REWARDS')} className={className}>
        <SkeletonLoader className="h-12 w-full" />
      </BaseBox>
    );
  }

  return (
    <BaseBox title={t('YOUR_REWARDS')} className={className}>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-12">
        {rewards.map((currency) => {
          return <UserReward key={currency.symbol} currency={currency.symbol} />;
        })}
      </div>

      {displayedDeposits?.length ? (
        <div className="mt-10">
          <SectionHeading text={`🗃 ${t('DEPOSITS')}`} />

          <DepositsTable items={displayedDeposits} refetchUserDeposits={refetch} />

          <BasePagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : null}
    </BaseBox>
  );
};

export default UserRewardsWidget;
