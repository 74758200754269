import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useContractRead } from 'wagmi';
import { AirdropRecipient } from '../../generateMerkleTree';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { merkleClaimableAirdropABI } from '../generated';
import { useChainId } from '../useChainId';

const fetchAirdropRecipients = async () => {
  const response = await axios<AirdropRecipient[]>('/airdrop/airdrop-recipients.json');
  return response.data;
};

export const useIsEligibleForAirdrop = (address: string) => {
  const { airdrop } = useProtocolContractAddresses();
  const { data } = useQuery({
    queryKey: ['airdrop'],
    queryFn: fetchAirdropRecipients,
  });

  const chainId = useChainId();
  const { data: isPaused } = useContractRead({
    abi: merkleClaimableAirdropABI,
    address: airdrop,
    functionName: 'paused',
    chainId,
  });

  const { data: hasClaimed, refetch } = useContractRead({
    abi: merkleClaimableAirdropABI,
    address: airdrop,
    enabled: !!address,
    functionName: 'hasClaimed',
    args: [address as `0x${string}`],
  });

  const recipient = data?.find(
    (reipient) => reipient.address.toLowerCase() === address.toLowerCase(),
  );

  if (isPaused) {
    return {
      isEligible: false,
      refetch,
    };
  }

  if (recipient && hasClaimed === false) {
    return {
      isEligible: true,
      amount: BigInt(recipient.amount),
      rewardAmount: BigInt(recipient.rewardAmount),
      refetch,
    };
  }

  return {
    isEligible: false,
    refetch,
  };
};
