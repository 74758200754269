import { useAccount, useContractRead } from 'wagmi';
import * as viem from 'viem';
import { erc20ABI } from '../generated';

export function useAllowance({
  tokenAddress,
  spenderAddress,
}: {
  tokenAddress: `0x${string}` | undefined;
  spenderAddress: `0x${string}` | undefined;
}) {
  const account = useAccount();
  const {
    data: allowance,
    isLoading,
    refetch,
  } = useContractRead({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'allowance',
    args: [account.address!, spenderAddress!],
    enabled:
      viem.isAddress(tokenAddress ?? '') &&
      viem.isAddress(spenderAddress ?? '') &&
      viem.isAddress(account.address!),
    watch: true,
  });

  return {
    allowance,
    isLoading,
    refetch,
  };
}
