import useTranslation from 'next-translate/useTranslation';
import { UseFormRegisterReturn } from 'react-hook-form';
import { STAKING_ASSETS } from '../../utils/constants';
import BaseSelect from '../base/BaseSelect';

function StakingAssetPicker({
  register,
  defaultValue,
}: {
  defaultValue?: string;
  register: UseFormRegisterReturn;
}) {
  const { t } = useTranslation('common');

  const options = [STAKING_ASSETS[0]].map((asset) => ({
    label: asset.symbol,
    value: asset.symbol,
  }));

  const defaultValueOption = defaultValue || STAKING_ASSETS[0].symbol;

  return (
    <div>
      <label htmlFor="currency" className="sr-only">
        {t('CURRENCY')}
      </label>

      {options.length > 1 ? (
        <BaseSelect
          id="currency"
          name="currency"
          defaultValue={defaultValueOption}
          optionClassName="text-right"
          options={options}
          register={register}
        />
      ) : (
        <span className="mr-4">{defaultValueOption}</span>
      )}
    </div>
  );
}

export default StakingAssetPicker;
