import { useRewardsAsset } from '../../utils/useAssets';
import { useEarnedRewards } from '../../utils/hooks/staking/useEarnedRewards';
import BaseButton from '../base/BaseButton';
import { useCollectRewards } from '../../utils/hooks/staking/useCollectRewards';
import * as viem from 'viem';
import useTranslation from 'next-translate/useTranslation';

const UserReward = ({ currency }: { currency: string }) => {
  const { t } = useTranslation('common');
  const rewardAsset = useRewardsAsset(currency);
  const {
    amountOfRewards,
    isLoading,
    refetch: refetchEarnedRewards,
  } = useEarnedRewards({
    stakingContractIndex: rewardAsset?.stakingContractIndex || 0,
  });
  const { collectRewards, collectRewardsStatus } = useCollectRewards({
    rewardAsset,
    onTransactionSuccess() {
      refetchEarnedRewards();
    },
  });

  const displayValue = viem.formatUnits(
    amountOfRewards ?? 0n,
    rewardAsset?.decimals || 18,
  );

  return (
    <div className="mb-3 flex w-full flex-wrap items-center justify-start border-t px-2 pt-4 first-of-type:border-t-0 first-of-type:pt-0 dark:border-brand--5 lg:border-t-0 lg:pt-0">
      <div className="w-full text-lg">
        {currency}: <strong>{displayValue}</strong>
      </div>

      {displayValue !== '0.0' && (
        <BaseButton
          variant="primary"
          size="small"
          className="my-3 w-full max-w-[180px] lg:my-4"
          onClick={collectRewards}
          isLoading={collectRewardsStatus === 'loading'}
          disabled={
            isLoading ||
            !useCollectRewards ||
            (collectRewardsStatus !== 'idle' && collectRewardsStatus !== 'success')
          }
        >
          {t('COLLECT')}
        </BaseButton>
      )}
    </div>
  );
};

export default UserReward;
