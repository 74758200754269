import { UseFormRegisterReturn } from 'react-hook-form';

interface BaseSelectProps {
  name: string;
  id: string;
  options: { label: string; value: number | string }[];
  disabled?: boolean;
  className?: string;
  optionClassName?: string;
  defaultValue: string;
  register: UseFormRegisterReturn;
}

function BaseSelect(props: BaseSelectProps) {
  return (
    <select
      id={props.id}
      defaultValue={props.defaultValue}
      className={
        props.className ??
        `disabled:cursor-not-allowed' block cursor-pointer rounded-lg border-none bg-transparent`
      }
      disabled={props.disabled}
      {...props.register}
    >
      {props.options.map((option) => (
        <option className={props.optionClassName} value={option.value} key={option.label}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default BaseSelect;
