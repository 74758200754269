import clsx from 'clsx';
import { useState } from 'react';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import useTranslation from 'next-translate/useTranslation';

const CopyButton = ({ fieldId, className }: { fieldId: string; className?: string }) => {
  const { t } = useTranslation('common');
  const [copied, setCopied] = useState(false);

  function copy() {
    const field = document.getElementById(fieldId) as HTMLInputElement | null;

    if (!field) return;

    field.select();
    field.setSelectionRange(0, 99999); // For mobile devices
    try {
      navigator.clipboard.writeText(field.value);
    } catch {
      document.execCommand('copy'); // workaround for iOS
    } finally {
      window.getSelection()?.removeAllRanges();
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 2500);
    }
  }

  return (
    <button
      className={clsx([
        'rounded p-2 transition',
        ...(copied
          ? ['bg-teal-600 px-3 text-white']
          : [
              'bg-brand--2 text-brand--5/80 hover:bg-brand--4 hover:text-white',
              'dark:bg-black/30 dark:text-brand--2/50 dark:hover:bg-brand--4 dark:hover:text-white',
            ]),
        className || '',
      ])}
      onClick={copy}
      type="button"
    >
      {copied && <span className="mr-3 mt-2">{`${t('COPIED')}!`}</span>}

      <ClipboardDocumentIcon className="inline h-6 w-6" />
    </button>
  );
};

export default CopyButton;
