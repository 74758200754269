import clsx from 'clsx';

export interface TableColumn {
  className: string;
  headerClassName?: string;
  label: string;
  slug: string;
}

interface TableHeadProps {
  columns: TableColumn[];
}

const headLabelClasses = [
  'text-brand--4',
  'font-medium',
  'text-xs',
  'mb-2',
  'uppercase',
  'tracking-widest',
];

const TableHead = ({ columns }: TableHeadProps) => {
  return (
    <div className="hidden xl:flex">
      {columns.map((column) => {
        return (
          <div
            key={column.slug}
            className={clsx([
              column.className,
              ...headLabelClasses,
              column.headerClassName,
            ])}
          >
            {column.label}
          </div>
        );
      })}
    </div>
  );
};

export default TableHead;
