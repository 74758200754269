import { useWaitForTransaction } from 'wagmi';

export const getMessageBasedOnStatus = (
  status: ReturnType<typeof useWaitForTransaction>['status'],
  messages: {
    idle?: string;
    loading?: string;
    error?: string;
    success?: string;
  },
) => {
  switch (status) {
    case 'idle':
      return messages.idle;
    case 'loading':
      return messages.loading;
    case 'error':
      return messages.error;
    case 'success':
      return messages.success || messages.idle;
  }
};
