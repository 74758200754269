import { useAutoAnimate } from '@formkit/auto-animate/react';
import * as viem from 'viem';
import useTranslation from 'next-translate/useTranslation';
import { useForm } from 'react-hook-form';
import { useAccount } from 'wagmi';
import { getMessageBasedOnStatus } from '../../utils/getMessageBasedOnStatus';
import { useAllowance } from '../../utils/hooks/token/useAllowance';
import { useApproveToken } from '../../utils/hooks/token/useApproveToken';
import { useStakeToken } from '../../utils/hooks/staking/useStakeToken';
import BaseBox from '../base/BaseBox';
import BaseButton from '../base/BaseButton';
import StakingInput from './StakingInput';
import StakingOutput from './StakingOutput';
import { useTokenBalanceOfUser } from '../../utils/hooks/token/useTokenBalanceOfUser';
import { parseToBigInt } from '../../utils/parseToBigInt';
// import StakingPeriodSelector from './StakingPeriodSelector';
import { useRewardsAsset, useStakingAsset } from '../../utils/useAssets';
import { useProtocolContractAddresses } from '../../utils/hooks/useProtocolContractAddresses';
import { STAKING_ASSETS, STAKING_REWARDS } from '../../utils/constants';
import { useStakeTokenWithPermit } from '../../utils/hooks/staking/useStakeTokenWithPermit';
import { useIsSmartContractWalletConnected } from '../../utils/hooks/useIsSmartContractWalletConnected';

function StakingWidget() {
  const { t } = useTranslation('common');

  const { address } = useAccount();
  const isSmartContractWalletConnected = useIsSmartContractWalletConnected();
  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const stakeTokenSymbol: string = watch('currency') || STAKING_ASSETS[0].symbol;
  const stakeTokenAmount: string = watch('amount');
  const rewardTokenSymbol: string = watch('reward') || STAKING_REWARDS[0].symbol;
  const lockPeriodId: string = watch('lockPeriod');

  const [buttonsParent] = useAutoAnimate<HTMLDivElement>();

  const selectedTokenToStake = useStakingAsset(stakeTokenSymbol);
  const selectedRewardAsset = useRewardsAsset(rewardTokenSymbol);

  const { locker } = useProtocolContractAddresses();

  const {
    allowance,
    isLoading: isLoadingAllowance,
    refetch: refetchAllowance,
  } = useAllowance({
    spenderAddress: locker,
    tokenAddress: selectedTokenToStake?.address,
  });

  const { approveStatus, approveToken } = useApproveToken({
    tokenAddress: selectedTokenToStake?.address,
    spenderAddress: locker,
    amount: parseToBigInt(stakeTokenAmount),
    onTransactionSuccess: () => {
      refetchAllowance();
    },
  });

  const { stakeTokenStatus, stakeToken } = useStakeToken({
    amount: parseToBigInt(stakeTokenAmount),
    selectedTokenToStake,
    selectedRewardAsset,
    allowance,
    lockPeriodId,
    onTransactionSubmitted() {
      resetForm();
      refetchAllowance();
    },
  });

  const { stakeTokenWithPermitStatus, permitTokensAndStake } = useStakeTokenWithPermit({
    amount: parseToBigInt(stakeTokenAmount),
    selectedTokenToStake,
    selectedRewardAsset,
    allowance,
    lockPeriodId,
    onTransactionSubmitted() {
      resetForm();
      refetchAllowance();
    },
  });

  const { data: stakingTokenBalace } = useTokenBalanceOfUser({
    tokenAddress: selectedTokenToStake?.address,
  });
  const maxAmountOfStakingToken = viem
    .formatUnits(stakingTokenBalace || 0n, selectedTokenToStake?.decimals || 0)
    .toString();

  const isAllowanceInsufficient =
    !isLoadingAllowance && allowance && parseToBigInt(stakeTokenAmount) > allowance;

  const isBalanceInsufficient =
    parseToBigInt(stakeTokenAmount) > (stakingTokenBalace || 0);

  return (
    <BaseBox title={`🚀 ${t('INVEST_IN_MILKY_ICE')}`} className="max-w-[480px]">
      <form
        onSubmit={handleSubmit(() => {
          stakeToken?.();
        })}
      >
        <StakingInput
          register={register}
          setValue={setValue}
          errors={errors}
          max={maxAmountOfStakingToken}
          whenClickedMax={() => setValue('amount', maxAmountOfStakingToken)}
        />

        <StakingOutput
          selectedRewardTokenSymbol={rewardTokenSymbol}
          setValue={(value) => setValue('reward', value)}
          errors={errors}
        />

        {isBalanceInsufficient && (
          <div className="mb-3 rounded-lg border border-red-600 bg-red-600/10 p-3 text-red-600">
            {t('NOT_ENOUGH_TOKENS')}
          </div>
        )}

        <div ref={buttonsParent}>
          {selectedTokenToStake?.supportsPermit && !isSmartContractWalletConnected ? (
            <>
              <BaseButton
                disabled={
                  parseToBigInt(stakeTokenAmount) === 0n ||
                  stakeTokenWithPermitStatus === 'loading' ||
                  isBalanceInsufficient
                }
                onClick={() => {
                  permitTokensAndStake?.();
                }}
                isLoading={stakeTokenWithPermitStatus === 'loading'}
                variant="primary"
                className="mx-auto mb-3  block w-full"
              >
                {address
                  ? getMessageBasedOnStatus(stakeTokenWithPermitStatus, {
                      idle: t('STAKE', { tokenSymbol: stakeTokenSymbol }),
                      error: t('STAKE', { tokenSymbol: stakeTokenSymbol }),
                      loading: t('STAKING'),
                    })
                  : t('CONNECT_WALLET_FIRST')}
              </BaseButton>
            </>
          ) : (
            <>
              {!isAllowanceInsufficient && (
                <BaseButton
                  disabled={!approveToken}
                  onClick={() => approveToken?.()}
                  isLoading={approveStatus === 'loading'}
                  variant="primary"
                  className="mx-auto mb-3 block w-full"
                >
                  {getMessageBasedOnStatus(approveStatus, {
                    idle: t('APPROVE', { tokenSymbol: stakeTokenSymbol }),
                    error: t('SOMETHING_WENT_WRONG'),
                    loading: t('APPROVING'),
                  })}
                </BaseButton>
              )}
              <BaseButton
                disabled={
                  !stakeToken ||
                  isAllowanceInsufficient ||
                  parseToBigInt(stakeTokenAmount) === 0n
                }
                isLoading={stakeTokenStatus === 'loading'}
                type="submit"
                variant="primary"
                className="mx-auto mb-3 block w-full"
              >
                {address
                  ? getMessageBasedOnStatus(stakeTokenStatus, {
                      idle: t('STAKE', { tokenSymbol: stakeTokenSymbol }),
                      error: t('SOMETHING_WENT_WRONG'),
                      loading: t('STAKING'),
                    })
                  : t('CONNECT_WALLET_FIRST')}
              </BaseButton>
            </>
          )}
        </div>
      </form>
    </BaseBox>
  );
}

export default StakingWidget;
