import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import * as viem from 'viem';
import useTranslation from 'next-translate/useTranslation';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { TransactionEventHandlers } from '../../../types/TransactionEventHandlers';
import { CONFIRMATIONS_AMOUNT } from '../../constants';
import { useNotifiacationHandlers } from '../../useToast';
import { erc20ABI } from '../generated';

export function useApproveToken({
  tokenAddress,
  spenderAddress,
  amount,
  onTransactionSubmitted,
  onTransactionSuccess,
  onSubmitError,
  onTransactionError,
  onTransactionSettled,
}: {
  tokenAddress: `0x${string}` | undefined;
  spenderAddress: string | undefined;
  amount: bigint | undefined;
} & TransactionEventHandlers) {
  const { t } = useTranslation('common');
  const handlers = useNotifiacationHandlers({
    onTransactionSubmitted,
    onTransactionSuccess,
    onSubmitError,
    onTransactionError,
    onTransactionSettled,
  });
  const addRecentTransaction = useAddRecentTransaction();

  const { config: approveConfig } = usePrepareContractWrite({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'approve',
    args: [spenderAddress as `0x${string}`, amount!],
    enabled: viem.isAddress(tokenAddress ?? '') && !!amount,
  });

  const { data: approveTokenData, write: approveToken } = useContractWrite({
    ...approveConfig,
    onSuccess(data) {
      handlers.onTransactionSubmitted?.(data.hash, t('APPROVING'));
    },
    onError(error) {
      handlers.onSubmitError?.(error);
    },
  });

  const { data: approveData, status: approveStatus } = useWaitForTransaction({
    hash: approveTokenData?.hash,
    confirmations: CONFIRMATIONS_AMOUNT,
    onSuccess() {
      handlers.onTransactionSuccess?.(t('APPROVED'));
      addRecentTransaction({
        hash: approveTokenData?.hash ?? '',
        description: t('APPROVED'),
      });
    },
    onError(error) {
      handlers.onTransactionError?.(error);
    },
    onSettled(data, error) {
      handlers.onTransactionSettled?.(data?.transactionHash ?? '', error);
    },
  });

  return {
    approveData,
    approveStatus,
    approveToken: approveToken as () => Promise<void>,
  };
}
